import React, { useEffect } from 'react';
import './Dashboard.css';
import Machines from '../machines/Machines';
import { dataAllInfoUser, getDrinks, getMachines } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import UserCard from '../userCard/UserCard';
import DrinkList from '../drinkList/DrinkList';
import IngredientsPanel from '../ingredientesPanel/IngredientesPanel';
import SalesAnalytics from '../salesAnalytics.jsx/SalesAnalytics';
import SalesByDate from '../salesByDate/SalesByDate';

const Home = () => {
    const dispatch = useDispatch();
    const userName = localStorage.getItem('userName');

    useEffect(() => {
        dispatch(dataAllInfoUser());
    }, [dispatch]);

    return (
        <>
            {localStorage.getItem('userName') !== null ? (
                <div className="dashboard-container">
                    <h1>Dashboard de {userName}</h1>
                    {/* <UserCard/> */}
                    <SalesByDate/>
                    <Machines />
                    <div className="dashboard-content">
                    
                        <DrinkList />
                        <IngredientsPanel />
                    </div>
                    
                    <SalesAnalytics/>
                    

                </div>
            ) : (
                <h1>No tienes acceso</h1>
            )}
        </>
    );
};

export default Home;
