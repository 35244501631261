import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../redux/actions';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import './Register.css'; 

const Register = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const  isAuthenticated = useSelector(state => state.isAuthenticated);
    const error = useSelector(state => state.error);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        dispatch(registerUser(userName, password));
        setLoading(false);
    };
    

    if (isAuthenticated) {
      if(localStorage.getItem('userName') !== null && localStorage.getItem('token') !== null  ){
            
            navigate('/home')
        }
    }

    useEffect(() => {
        if(localStorage.getItem('userName') !== null && localStorage.getItem('token') !== null  ){
            
            navigate('/home')
        }
    },[])

    return (
        <div className="login-container">
            {loading ? (
                <div className="spinner-container">
                    <ClipLoader color="#36d7b7" loading={loading} size={50} />
                </div>
            ) : (
                <form className="login-form" onSubmit={handleSubmit}>
                    <h2>Register</h2>
                    {error && <p className="error-message">{error}</p>}
                    <div className="input-group">
                        <label>Username</label>
                        <input
                            type="text"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            placeholder="Enter username"
                            required
                        />
                    </div>

                    <div className="input-group">
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter password"
                            required
                        />
                    </div>

                    <button type="submit" className="submit-button">register</button>
                    <p>ya tienes cuenta? <Link to="/">Inicia sesión</Link></p>

                </form>
            )}
        </div>
    );
};

export default Register;
