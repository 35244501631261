import React from 'react';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import './SalesAnalytics.css';

// Registramos los elementos de Chart.js que vamos a usar
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const SalesAnalytics = () => {
    const analisis = useSelector((state) => state.analisis);

    // Datos simulados para el gráfico de ventas a lo largo del tiempo
    const ventasData = [
        { time: '1h', value: 10 },
        { time: '2h', value: 15 },
        { time: '3h', value: 30 },
        { time: '4h', value: 25 },
        { time: '5h', value: 40 },
        { time: '6h', value: 45 },
        { time: '7h', value: 50 },
    ];

    // Generamos los datos para el gráfico
    const data = {
        labels: ventasData.map((data) => data.time),  // Las etiquetas (tiempo)
        datasets: [
            {
                label: 'Ventas',
                data: ventasData.map((data) => data.value),  // Los valores de ventas
                borderColor: 'rgba(75, 192, 192, 1)',  // Color de la línea
                backgroundColor: 'rgba(75, 192, 192, 0.2)',  // Color de fondo (poco visible)
                fill: true,  // Para llenar el área debajo de la línea
                tension: 0.4,  // Para hacer que la línea sea más suave
                pointRadius: 5,  // Radio de los puntos en la línea
            },
        ],
    };

    // Configuración de opciones del gráfico
    const options = {
        responsive: true,
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            title: {
                display: true,
                text: 'Análisis de Ventas',
            },
            tooltip: {
                enabled: true,
            },
        },
    };

    return (
        <div className="sales-analytics">
            <h3>📈 Análisis de Ventas</h3>
            <div className="chart-container">
                {/* Gráfico de líneas */}
                <Line data={data} options={options} />
            </div>
            <div className="metrics">
                <p>Máquina Más Vendida: {analisis.maquinaMasVendida ? analisis.maquinaMasVendida.mac : 'No disponible'}</p>
                <p>Total Vendido de la Máquina: {analisis.maquinaMasVendida ? analisis.maquinaMasVendida.totalVendido : 'No disponible'}</p>
                <p>Trago Más Vendido: {analisis.tragoMasVendido ? analisis.tragoMasVendido.trago : 'No disponible'}</p>
                <p>Cantidad Vendida del Trago: {analisis.tragoMasVendido ? analisis.tragoMasVendido.cantidad : 'No disponible'}</p>
                <p>Recomendación: {analisis.recommendacionParaVender ? analisis.recommendacionParaVender.join(', ') : 'No hay recomendaciones'}</p>
            </div>
        </div>
    );
};

export default SalesAnalytics;
