import React from 'react';
import './Policies.css'; 

const Policies = () => {
    return (
        <div className="policies-container">
            <h1>Políticas de Uso de Flashpe</h1>
            <p>
                Bienvenido a nuestras políticas de uso. Al utilizar nuestra plataforma, aceptas las siguientes condiciones:
            </p>
            <ol>
                <li>No está permitido el uso indebido de la aplicación.</li>
                <li>Debe respetarse la privacidad de otros usuarios.</li>
                <li>Los datos proporcionados serán tratados de acuerdo con nuestras políticas de privacidad.</li>
                <li>Cualquier incumplimiento podría resultar en la suspensión de tu cuenta.</li>
            </ol>
            <p>Para más información, contáctanos en: soporte@flashpe.com</p>
        </div>
    );
};

export default Policies;
