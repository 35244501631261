import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVentasMonth } from '../../redux/actions';
import VentaCard from './VentasCard';
import './MonthVentas.css';

const MonthVentas = ({ mac }) => {
    const ventasMonth = useSelector((state) => state.ventasMonth);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getVentasMonth(mac));
    }, [dispatch, mac]);

    if (!ventasMonth || ventasMonth.length === 0) {
        return <h3 className="no-data">No hay datos disponibles para este mes.</h3>;
    }

    const {
        mes,
        totalGanancias,
        totalIngresos,
        totalTragosVendidos,
        totalVentas,
        ventas
    } = ventasMonth[0] || {};

    return (
        <div className="month-ventas-container">
            <h2 className="month-title">📅 Ventas del Mes - {mes}</h2>
            
            <div className="resumen-mensual">
                <h4>Total Ingresos: <span>${totalIngresos}</span></h4>
                <h4>Total Ganancias: <span>${totalGanancias}</span></h4>
                <h4>Total Tragos Vendidos: <span>{totalTragosVendidos}</span></h4>
                <h4>Total Ventas: <span>{totalVentas}</span></h4>
            </div>

            <div className="ventas-grid">
                {ventas?.map((venta) => (
                    <VentaCard key={venta.id} venta={venta} />
                ))}
            </div>
        </div>
    );
};

export default MonthVentas;
