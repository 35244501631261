import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateNameMachine } from "../../redux/actions";
import './Edit.css';

const Edit = ({ id }) => {
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [newName, setNewName] = useState(""); 
    const dispatch = useDispatch();

    const handleOpenModal = () => {
        setIsModalOpen(true); 
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); 
        setNewName(""); 
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newName.trim()) {
            dispatch(updateNameMachine(id, newName));
            handleCloseModal(); 
        }
    };

    return (
        <>
            <button onClick={handleOpenModal} className="edit-button">
                Edit
            </button>

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Edit Machine Name</h3>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                                placeholder="New machine name"
                                className="input-field"
                            />
                            <div className="modal-actions">
                                <button type="submit" className="save-button">
                                    Save
                                </button>
                                <button
                                    type="button"
                                    onClick={handleCloseModal}
                                    className="cancel-button"
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default Edit;
