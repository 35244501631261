import React, { useState, useMemo } from 'react';
import './SemanaCard.css';

const SemanaCard = ({ semana }) => {
    const { semana: nombreSemana, total, totalVendido, ventas } = semana;

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // 🧠 Paginación de Ventas
    const paginatedVentas = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return ventas.slice(startIndex, endIndex);
    }, [ventas, currentPage]);

    // 🧠 Top 3 Tragos Más Vendidos
    const topTragos = useMemo(() => {
        const tragoCount = ventas.reduce((acc, venta) => {
            if (venta.trago) {
                acc[venta.trago] = (acc[venta.trago] || 0) + 1;
            }
            return acc;
        }, {});

        return Object.entries(tragoCount)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 3);
    }, [ventas]);

    const totalPages = Math.ceil(ventas.length / itemsPerPage);

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <div className="semana-card">
            <h5>📅 {nombreSemana}</h5>
            <p><strong>Total Ventas:</strong> {total}</p>
            <p><strong>Total Vendido:</strong> ${totalVendido}</p>

            <h6>🏆 Top 3 Tragos Más Vendidos:</h6>
            <ol>
                {topTragos.map(([trago, cantidad], index) => (
                    <li key={index}>
                        🥇 <strong>{trago}</strong> - {cantidad} unidades
                    </li>
                ))}
            </ol>

            <h6>📋 Detalles de Ventas (Página {currentPage}/{totalPages}):</h6>
            <ul>
                {paginatedVentas.map((venta, index) => (
                    <li key={index}>
                        <strong>🛠️ Trago:</strong> {venta.trago || 'Desconocido'} -  
                        <strong> 🏷️ Precio:</strong> ${venta.precio || 'No disponible'} -  
                        <strong> 📍 Máquina:</strong> {venta.maquina || 'No disponible'} -  
                        <strong> 🗓️ Fecha:</strong> {new Date(venta.fechaVenta).toLocaleDateString() || 'No disponible'}
                    </li>
                ))}
            </ul>

            {/* Paginación */}
            <div className="pagination">
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    ◀️ Anterior
                </button>
                <span>Página {currentPage} de {totalPages}</span>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    Siguiente ▶️
                </button>
            </div>
        </div>
    );
};

export default SemanaCard;
