import React from 'react';
import { Link } from 'react-router-dom'; // Importamos Link para la navegación interna
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <p>&copy; 2024 Fernet Machine. Todos los derechos reservados.</p>
            <ul className="footer-links">
                <li><a href="https://facebook.com">Facebook</a></li>
                <li><a href="https://twitter.com">Twitter</a></li>
                <li><a href="https://instagram.com">Instagram</a></li>
                <li><Link to="/policies">Políticas de Uso</Link></li> {/* Enlace al componente de políticas */}
            </ul>
        </footer>
    );
};

export default Footer;
