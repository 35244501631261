import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMacVentas } from "../../redux/actions";
import { useParams, useNavigate } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import "./ventas.css";
import MonthVentas from "./MonthVentas";
import WeekVentas from "./WeekVentas";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const formatFecha = (fechaISO) => {
    try {
        if (!fechaISO) throw new Error("Fecha inválida");
        return format(new Date(fechaISO), "dd 'de' MMMM 'de' yyyy, HH:mm", { locale: es });
    } catch (error) {
        console.error("Error formateando fecha:", error);
        return "Fecha no disponible";
    }
};

const DataVentas = () => {
    const { mac } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const DataVenta = useSelector((state) => state.ventasMac);

    const ventas = DataVenta?.ventas || [];
    const totalVentas = ventas.length;

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedView, setSelectedView] = useState("month"); // 'month' o 'week'

    const itemsPerPage = 6; 

    const indexOfLastVenta = currentPage * itemsPerPage;
    const indexOfFirstVenta = indexOfLastVenta - itemsPerPage;
    const currentVentas = ventas.slice(indexOfFirstVenta, indexOfLastVenta);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const nextPage = () => {
        if (currentPage < Math.ceil(totalVentas / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const dataChart = {
        labels: ventas.map((venta) => venta.nombreTrago),
        datasets: [
            {
                label: "Ganancia por Trago",
                data: ventas.map((venta) => venta.ganancia),
                backgroundColor: "#0077b5",
                borderColor: "#0077b5",
                borderWidth: 1,
            },
        ],
    };

    useEffect(() => {
        dispatch(getMacVentas(mac));
    }, [dispatch, mac]);

    return (
        <div className="ventas-container">
            <button className="back-button" onClick={() => navigate(-1)}>
              Volver
            </button>

            <div className="ventas-header">
                <h2>Información de las Ventas</h2>
                <div className="ventas-resumen">
                    <h4>Total Ingresos: <span>${DataVenta?.totalIngresos || 0}</span></h4>
                    <h4>Total Ganancias: <span>${DataVenta?.totalGanancias || 0}</span></h4>
                    <h4>Total Tragos Vendidos: <span>{DataVenta?.totalTragosVendidos || 0}</span></h4>
                    <h4>Total Tragos: <span>{totalVentas}</span></h4>
                </div>
            </div>

            {/* Menú de Selección */}
            <div className="ventas-menu">
                <button 
                    onClick={() => setSelectedView("month")}
                    className={selectedView === "month" ? "menu-button active" : "menu-button"}
                >
                    Ventas por Mes
                </button>
                <button 
                    onClick={() => setSelectedView("week")}
                    className={selectedView === "week" ? "menu-button active" : "menu-button"}
                >
                    Ventas por Semana
                </button>
            </div>

            {/* Renderizado Condicional */}
            <div className="ventas-view">
                {selectedView === "month" && <MonthVentas mac={mac} />}
                {selectedView === "week" && <WeekVentas mac={mac} />}
            </div>

            <div className="chart-container">
                <h3>Ganancia por Trago</h3>
                <Bar data={dataChart} options={{ responsive: true }} />
            </div>

            <div className="ventas-detalle">
                {currentVentas.length > 0 ? (
                    currentVentas.map((venta) => (
                        <div className="venta-card" key={venta.id}>
                            <h5>Trago: {venta.nombreTrago}</h5>
                            <p><strong>Cantidad:</strong> {venta.cantidad}</p>
                            <p><strong>Precio Unitario:</strong> ${venta.precioUnitario}</p>
                            <p><strong>Costo:</strong> ${venta.costo}</p>
                            <p><strong>Total Venta:</strong> ${venta.total}</p>
                            <p><strong>Ganancia:</strong> ${venta.ganancia}</p>
                        </div>
                    ))
                ) : (
                    <h4>No hay información de ventas disponible.</h4>
                )}
            </div>

            <div className="pagination">
                <span className="page-arrow" onClick={prevPage}>&lt; Anterior</span>
                <span className="page-number">{currentPage}</span>
                <span className="page-arrow" onClick={nextPage}>Siguiente &gt;</span>
            </div>
        </div>
    );
};

export default DataVentas;
