import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './Navbar.css'; 
import { logout } from '../../redux/actions';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false); // Estado para manejar la apertura/cierre del menú
    const userName = localStorage.getItem('userName');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout());
        if (localStorage.getItem('userName') === null) {
            navigate('/');
        }
    };

    const toggleMenu = () => setMenuOpen(!menuOpen); // Función para cambiar el estado del menú

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <h1 className="logo-animado">Flash-pe</h1> {/* Texto animado */}
            </div>
            <div className={`hamburger-menu ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            <ul className={`navbar-links ${menuOpen ? 'open' : ''}`}>
                {localStorage.getItem('userName') != null ? (
                    <>
                        <li><Link to='/machines'>Machines</Link></li>
                        <li><Link to="/home">{userName}</Link></li>
                        <button className='logout-button' onClick={handleLogout}>
                            <i className='fas fa-power-off'></i>
                        </button>
                    </>
                ) : <></>}
            </ul>
        </nav>
    );
};

export default Navbar;
