import React from 'react';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import './SalesByDate.css';
// Registrar los componentes necesarios para Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SalesByDate = () => {
    const ventasPorFechas = useSelector((state) => state.ventasPorFechas);

    // Asegúrate de que existen las propiedades dentro de ventasPorFechas
    const ventasPorDia = ventasPorFechas?.VentasPorDia || {};
    const ventasPorSemana = ventasPorFechas?.VentasPorSemana || {};
    const ventasPorMes = ventasPorFechas?.ventasPorMes || {};

    // Función para crear datos para el gráfico
    const crearDatosGrafico = (ventas) => {
        const labels = Object.keys(ventas); // Etiquetas (fechas, semanas, meses)
        const data = Object.values(ventas);  // Valores de las ventas

        return {
            labels: labels,
            datasets: [
                {
                    label: 'Ventas Totales',
                    data: data,
                    borderColor: 'rgb(75, 192, 192)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    tension: 0.4, // Suavizar la línea
                },
            ],
        };
    };

    return (
        <div className="sales-by-date">
            <h3>📅 Ventas por Fecha</h3>

            <h4>Ventas por Día</h4>
            {Object.entries(ventasPorDia).length > 0 ? (
                <div>
                    <Line data={crearDatosGrafico(ventasPorDia)} />
                    {Object.entries(ventasPorDia).map(([key, value]) => (
                        <p key={key}>{key}: Total: ${value}</p>
                    ))}
                </div>
            ) : (
                <p>No hay ventas por día disponibles.</p>
            )}

            <h4>Ventas por Semana</h4>
            {Object.entries(ventasPorSemana).length > 0 ? (
                <div>
                    <Line data={crearDatosGrafico(ventasPorSemana)} />
                    {Object.entries(ventasPorSemana).map(([key, value]) => (
                        <p key={key}>{key}: Total: ${value}</p>
                    ))}
                </div>
            ) : (
                <p>No hay ventas por semana disponibles.</p>
            )}

            <h4>Ventas por Mes</h4>
            {Object.entries(ventasPorMes).length > 0 ? (
                <div>
                    <Line data={crearDatosGrafico(ventasPorMes)} />
                    {Object.entries(ventasPorMes).map(([key, value]) => (
                        <p key={key}>{key}: Total: ${value}</p>
                    ))}
                </div>
            ) : (
                <p>No hay ventas por mes disponibles.</p>
            )}
        </div>
    );
};

export default SalesByDate;
