import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import './MachinesDetails.css';
import { getIngredientes, updateNameDrinks, adNewPrices,getMachines,getDrinks } from '../../redux/actions';

const MachinesDetails = () => {
    const { mac} = useParams();
    const tragos = useSelector((state) => state.tragos);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [globalLoading, setGlobalLoading] = useState(false);

    // Estados para edición
    const [editingName, setEditingName] = useState(null); // ID del trago que se está editando el nombre
    const [editingPrice, setEditingPrice] = useState(null); // ID del trago que se está editando el precio
    const [newValue, setNewValue] = useState(''); // Valor temporal para nombre o precio

    useEffect(() => {
    dispatch(getDrinks(mac));
    }, [dispatch]);



    const handlerIng = async (id) => {
        setLoading(true);
        await dispatch(getIngredientes(id));
        await dispatch(getDrinks(mac));
       setLoading(false);
        navigate(`/ingredientes/${id}`);
    };

    const startEditingName = async(id, currentName) => {
        setEditingName(id);
        await dispatch(getDrinks(mac));
        setNewValue(currentName);
    };

    const startEditingPrice = async(id, currentPrice) => {
        setEditingPrice(id);
        await dispatch(getDrinks(mac));
        setNewValue(currentPrice);
    };

    const saveName = async (id) => {
        setGlobalLoading(true);
        await dispatch(updateNameDrinks(id, newValue));
        setEditingName(null);
        setNewValue('');
        await dispatch(getIngredientes(id));
        await dispatch(getDrinks(mac));
        setGlobalLoading(false);
    };

    const savePrice = async (id) => {
        setGlobalLoading(true);
        await dispatch(adNewPrices(id, newValue));
        setEditingPrice(null);
        setNewValue('');
        await dispatch(getIngredientes(id));
        await dispatch(getDrinks(mac));
        setGlobalLoading(false);
    };

    return (
        <div className="details-container">
            {globalLoading && (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                </div>
            )}

            <button className="back-button" onClick={() => navigate(-1)}>
                Volver
            </button>

            <h1>Tragos de la máquina</h1>
            <div>
                {Array.isArray(tragos) && tragos.length > 0 ? (
                    tragos.map((e) => (
                        <div key={e._id}>
                            {/* Edición de Nombre */}
                            {editingName === e._id ? (
                                <div className="floating-input">
                                    <input
                                        type="text"
                                        value={newValue}
                                        onChange={(event) => setNewValue(event.target.value)}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') saveName(e._id);
                                        }}
                                    />
                                    <button onClick={() => saveName(e._id)}>Guardar</button>
                                    <button onClick={() => setEditingName(null)}>Cancelar</button>
                                </div>
                            ) : (
                                <>
                                    <h2>{e.nombre}</h2>
                                    <button onClick={() => startEditingName(e._id, e.nombre)}>Cambiar nombre</button>
                                </>
                            )}

                            <span>
                                {Array.isArray(e.precios) && e.precios.length > 0 ? (
                                    e.precios.map((s) => (
                                        <div key={s._id}>
                                            {/* Edición de Precio */}
                                            {editingPrice === s._id ? (
                                                <div className="floating-input">
                                                    <input
                                                        type="number"
                                                        value={newValue}
                                                        onChange={(event) => setNewValue(event.target.value)}
                                                        onKeyDown={(event) => {
                                                            if (event.key === 'Enter') savePrice(e._id);
                                                        }}
                                                    />
                                                    <button onClick={() => savePrice(e._id)}>Guardar</button>
                                                    <button onClick={() => setEditingPrice(null)}>Cancelar</button>
                                                </div>
                                            ) : (
                                                <>
                                                    <h3>Precio: {s.precio}</h3>
                                                    <button onClick={() => startEditingPrice(s._id, s.precio)}>Editar precio</button>
                                                </>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <p>No hay precios disponibles</p>
                                )}
                            </span>
                            <h3>Ingredientes: {Array.isArray(e.ingredientes) ? e.ingredientes.length : 0}</h3>
                            <button onClick={() => handlerIng(e._id)} disabled={loading}>
                                {loading ? 'Cargando...' : 'Ver Ingredientes'}
                            </button>
                        </div>
                    ))
                ) : (
                    <div>
                        <h3>{tragos?.message || 'No se encontraron tragos'}</h3>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MachinesDetails;
