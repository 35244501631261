import React from 'react';
import { useSelector } from 'react-redux';
import './DrinkList.css'; // Importa los estilos
import { useNavigate } from 'react-router-dom';

const DrinkList = () => {
    // Aseguramos que tragos sea un array vacío si no existe
    const tragos = useSelector((state) => state.tragos) || []; 

    // Verificamos que tragos sea un array y que tenga elementos antes de acceder a tragos[0].tragos
    const trago = (Array.isArray(tragos) && tragos.length > 0 && Array.isArray(tragos[0].tragos)) ? tragos[0].tragos : [];

    const navigate = useNavigate();

    const handlerIng = (id) => {
        navigate(`/ingredientes/${id}`);
    }

    return (
        <div className="drink-list">
            <h3>🍹 Tragos</h3>
            <ul>
                {trago.length > 0 ? (
                    trago.map((e) => (
                        <li key={e._id} onClick={() => handlerIng(e._id)}>{e.nombre}</li>
                    ))
                ) : (
                    <li>No hay tragos disponibles</li>
                )}
            </ul>
        </div>
    );
};

export default DrinkList;
