import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVentasWeek } from '../../redux/actions';
import SemanaCard from './SemanaCard';
import './WeekVentas.css';

const WeekVentas = ({ mac }) => {
    const ventasWeek = useSelector((state) => state.ventasWeek);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getVentasWeek(mac));
    }, [dispatch, mac]);

    if (!ventasWeek || !ventasWeek.respuestaVentasSemana) {
        return <h3 className="no-data">No hay datos disponibles para esta semana.</h3>;
    }

    const { totalGlobal, totalGlobalVendido, respuestaVentasSemana } = ventasWeek;

    return (
        <div className="week-ventas-container">
            <h2 className="week-title">📅 Ventas por Semana</h2>
            
            <div className="resumen-semanal">
                <h4>Total Global: <span>{totalGlobal}</span></h4>
                <h4>Total Global Vendido: <span>${totalGlobalVendido}</span></h4>
            </div>

            <div className="semanas-grid">
                {respuestaVentasSemana.map((semana, index) => (
                    <SemanaCard key={index} semana={semana} />
                ))}
            </div>
        </div>
    );
};

export default WeekVentas;
