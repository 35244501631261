export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const UPDATE_NAME_MACHINE = 'UPDATE_NAME_MACHINE';

export const LOGOUT = 'LOGOUT';

export const GET_MACHINE_STATUS  = 'GET_MACHINE_STATUS ';
export const UPDATE_NAME_DRINKS = 'UPDATE_NAME_DRINKS';
export const GET_DRINKS = 'GET_DRINKS';

export const UPDATE_PRECIO = 'UPDATE_PRECIO';

export const GET_MACHINE = 'GET_MACHINE';
export const GET_ING = 'GET_ING';
export const GET_MAC_VENTAS = 'GET_MAC_VENTAS';

export const GET_INFO_ALL_USER = 'GET_INFO_ALL_USER';
export const FAIL_GET_MACHINE = 'FAIL_GET_MACHINE';

export const FAILED_GET_DRINKS = 'FAILED_GET_DRINKS';


export const GET_VENTS_MONTH = "GET_VENTS_MONTH";
export const GET_VENTAS_WEEK = 'GET_VENTAS_WEEK';
export const GET_VENTAS_WEEKEND = 'GET_VENTAS_WEEKEND';


export const CREATE_INGREDIENTE = 'CREATE_INGREDIENTE';
export const UPDATE_INGREDIENTE = 'UPDATE_INGREDIENTE';
export const DELETED_INGREDIENTE = 'DELETED_INGREDIENTE';
export const STATS_INGREDIENTES = 'STATS_INGREDIENTES'; 