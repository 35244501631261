import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMachines, getDrinks, getMachineStatus, getMacVentas} from '../../redux/actions';
import './Machines.css';
import Edit from '../Edit/Edit';
import MonthVentas from '../ventas/MonthVentas';

const Machines = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const machines = useSelector((state) => state.machines);
    const machineStatus = useSelector((state) => state.machineStatus);
    const [currentMachineIndex, setCurrentMachineIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    
    const handlerGetDrinks = (mac) => {
        dispatch(getDrinks(mac));
        navigate(`/detail/${mac}`);
    };

    const handlerVentas = ( mac ) => {
        navigate(`/ventas/${mac}`)
    }

    useEffect(() => {
        dispatch(getMachines()).then(() => setLoading(false));
    }, [dispatch]);

    useEffect(() => {
        if (!machines || machines.length === 0) return;

        const interval = setInterval(() => {
            const machine = machines[currentMachineIndex];

            if (machine) {
                dispatch(getMachineStatus(machine.mac))
                    .catch((error) => console.error(`Error al obtener estado de ${machine.mac}:`, error));
            }

            setCurrentMachineIndex((prevIndex) =>
                prevIndex === machines.length - 1 ? 0 : prevIndex + 1
            );
        }, 15000);

        return () => clearInterval(interval);
    }, [machines, currentMachineIndex, dispatch]);

    if (loading) {
        return (
            <div className="spinner-container">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="machines-container">
            <h2>Tus Máquinas</h2>

            {machines && machines.length > 0 ? (
                <div className="machines-list">
                    {machines.map((machine) => (
                        <div key={machine._id} className="machine-card">
                            <div className="machine-info">
                                <Edit id={machine._id} />
                                <h3 className="machine-mac">
                                    {machine.nombre}
                                </h3>
                                <h3 className="machine-owner">
                                    <span>{machine.dueño}</span>
                                </h3>
                                <div 
                                    className={`status-indicator ${
                                        machineStatus[machine.mac] ? 'online' : 'offline'
                                    }`}
                                ></div>
                                <div>
                                    
                                </div>
                                <button
                                    onClick={() => handlerGetDrinks(machine.mac )}
                                    className="btn-drinks"
                                >
                                    Ver bebidas
                                </button>
                                <button onClick={()=> handlerVentas(machine.mac) } className="btn-drinks"> ver ventas</button>
                            </div>

                        </div>
                    ))}
                </div>
            ) : (
                <p>No tienes máquinas registradas.</p>
            )}
        </div>
    );
};

export default Machines;
