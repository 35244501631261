import React from 'react';
import './VentasCard.css';

const VentaCard = ({ venta }) => {
    const { nombreTrago, cantidad, precioUnitario, costo, total, ganancia } = venta;

    return (
        <div className="venta-card">
            <h5>{nombreTrago}</h5>
            <p><strong>Cantidad:</strong> {cantidad}</p>
            <p><strong>Precio Unitario:</strong> ${precioUnitario}</p>
            <p><strong>Costo:</strong> ${costo}</p>
            <p><strong>Total Venta:</strong> ${total}</p>
            <p><strong>Ganancia:</strong> ${ganancia}</p>
        </div>
    );
};

export default VentaCard;
