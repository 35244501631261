import { 
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    GET_MACHINE,
    GET_DRINKS,
    GET_MACHINE_STATUS,
    GET_ING,
    GET_MAC_VENTAS,
    FAIL_GET_MACHINE,
    GET_VENTS_MONTH,
    GET_VENTAS_WEEK,
    GET_VENTAS_WEEKEND,
    UPDATE_INGREDIENTE,
    GET_INFO_ALL_USER,
    DELETED_INGREDIENTE
 } from './actions-types'


const initialState = {
    isAuthenticated: false,
    user: null,
    loading: true,
    error: null,
    machines: [],
    tragos: [],
    Ingredientes:[],
    ventasMac:[],
    ventasMonth:[],
    ventasWeek: [],
    ventasWeekend:[],
    machineStatus: {},
    ingredientesValidos: [],
    ingredientesReponer: [],
    ventas: [],
    analisis: {
        maquinaMasVendida: null,
        tragoMasVendido: null,
        recommendacionParaVender: null,
    },
    ventasPorFechas: {
        VentasPorDia: [],
        VentasPorSemana: [],
        ventasPorMes: [],
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INFO_ALL_USER:
    return {
        ...state,
        user: action.payload.usuario,
        machines: action.payload.maquinas,
        tragos: action.payload.Tragitos,
        ingredientesValidos: action.payload.ingredientesValidos,
        ingredientesReponer: action.payload.ingredientesReponer,
        ventas: action.payload.ventas,
        analisis: {
            maquinaMasVendida: action.payload.analisis.maquinaMasVendida,
            tragoMasVendido: action.payload.analisis.tragoMasVendido,
            recommendacionParaVender: action.payload.analisis.recommendacionParaVender,
        },
        ventasPorFechas: {
            VentasPorDia: action.payload.ventasPorFechas.VentasPorDia,
            VentasPorSemana: action.payload.ventasPorFechas.VentasPorSemana,
            ventasPorMes: action.payload.ventasPorFechas.ventasPorMes,
        }
    };

        case "FETCH_INGREDIENTES_SUCCESS":
            return {
                ...state,
                ingredientes: action.payload,
            };
        case UPDATE_INGREDIENTE:
            return {
                ...state,
                ingredientes: state.ingredientes.map(ingrediente =>
                    ingrediente._id === action.payload._id ? action.payload : ingrediente
                ),
            };
        case DELETED_INGREDIENTE:
            return {
                ...state,
                ingredientes: state.ingredientes.filter(ingrediente => ingrediente._id !== action.payload),
            };

        case GET_VENTAS_WEEKEND:
            return{
                ...state,
                ventasWeekend: action.payload,
            }
        case GET_VENTAS_WEEK:
            return{
                ...state,
                ventasWeek: action.payload
            }
        case GET_VENTS_MONTH:
        return{
            ...state,
            ventasMonth: action.payload
        }
        case GET_MAC_VENTAS:
            
            return{
                ...state,
                ventasMac: action.payload
            }
        case GET_ING:
            return{
                ...state,
                Ingredientes: action.payload
            }
        case GET_MACHINE_STATUS:
            return {
                ...state,
                machineStatus: {
                    ...state.machineStatus,
                    [action.payload.mac]: action.payload.status,
                },
            };
        case GET_DRINKS:
            return {
                ...state,
                tragos: action.payload,
            };
        // case FAILED_GET_DRINKS:
        //     return {
        //         ...state,
        //         tragos: action.payload,
        //     }
        case GET_MACHINE: 
            return{
                ...state,
                machines: action.payload,
            };
            case FAIL_GET_MACHINE:
                return {
                    ...state,
                    machines: action.payload
                }
        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                loading: true
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
};

export default reducer;
