import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reponerStockIngrediente } from '../../redux/actions';
import './IngredientsPanel.css'; // Importa los estilos

const IngredientsPanel = () => {
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [cantidadReponer, setCantidadReponer] = useState(0);
    const [ingredienteSeleccionado, setIngredienteSeleccionado] = useState(null);
    
    const ingredientesReponer = useSelector((state) => state.ingredientesReponer);
    const dispatch = useDispatch();

    // Mostrar alerta al montar el componente
    useEffect(() => {
        if (ingredientesReponer.length > 0) {
            const nombresReponer = ingredientesReponer.map((ing) => ing.nombre).join(', ');
            alert(`⚠️ Ingredientes a reponer: ${nombresReponer}`);
        }
    }, [ingredientesReponer]);

    const handleReponerClick = (ingrediente) => {
        console.log('Ingrediente seleccionado:', ingrediente.nombre); // Solo mostrar el nombre
        setIngredienteSeleccionado(ingrediente);
        setShowSubMenu(true); // Mostrar submenú
    };

    const handleReponerStock = () => {
        if (cantidadReponer <= 0) {
            alert('Por favor ingresa una cantidad válida.');
            return;
        }

        // Despachar la acción para reponer el stock
        console.log('Despachando acción para reponer stock:', ingredienteSeleccionado.nombre, cantidadReponer); // Depuración
        dispatch(reponerStockIngrediente(ingredienteSeleccionado.nombre, cantidadReponer));
        
        // Restablecer el estado
        setShowSubMenu(false);
        setCantidadReponer(0);

        // Refrescar la página después de la acción
        window.location.reload();
    };

    return (
        <div className="ingredients-panel">
            <h3>🥗 Ingredientes Por reponer</h3>
            {ingredientesReponer.length > 0 && (
                <div className="reponer">
                    <strong>⚠️ Ingredientes a reponer:</strong>
                    <ul>
                        {ingredientesReponer.map((ing) => (
                            <li
                                key={ing._id}
                                className="reponer-item"
                                onClick={() => handleReponerClick(ing)} // Clic para mostrar submenú
                            >
                                {ing.nombre}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {/* Submenú para ingresar cantidad a reponer */}
            {showSubMenu && (
                <div className="sub-menu">
                    <h4>Reponer Stock de {ingredienteSeleccionado?.nombre}</h4>
                    <label htmlFor="cantidadReponer">Cantidad a reponer (en ml):</label>
                    <input
                        type="number"
                        id="cantidadReponer"
                        value={cantidadReponer}
                        onChange={(e) => setCantidadReponer(Number(e.target.value))}
                        placeholder="Ejemplo: 1000 (1 litro)"
                    />
                    <button onClick={handleReponerStock}>Confirmar Reposición</button>
                    <button onClick={() => setShowSubMenu(false)}>Cancelar</button>
                </div>
            )}
        </div>
    );
};

export default IngredientsPanel;
